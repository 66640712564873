import ApiInstance from './ApiInstance.js'

export default {
  getJobApplications() {
    return ApiInstance({ requiresAuth: true }).get('/job-applications');
  },
  changeJobApplicationFavoriteStatus(id) {
    return ApiInstance({ requiresAuth: true }).get(`/job-application-favorite/${id}`);
  },
  changeJobApplicationStatus(Data) {
    return ApiInstance({ requiresAuth: true }).post('/job-application-bulk-action-proceed', Data);
  },
  deleteJobApplication(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/job-application-delete/${id}`);
  },
  getConversations(id,id2)
  {
    var ids = `${id}-${id2}`;
    return ApiInstance({ requiresAuth: true }).get(`/get-conversations/${ids}`);
  }
}
